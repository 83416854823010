
import readingTime from "reading-time";

export default {
  name: "BlogDetailPage",
  data() {
    return {
      post: {
        title: "",
        summery: "",
        body: "",
        createdAt: "",
        admin: {
          name: "",
          email: "",
        },
        category: {
          title: "",
        },
        slug: "",
        views: "",
        rate: 0,
      },
      rules: {
        required: (v) => !!v || "الزامی می باشد.",
        min: (v) => (v && v.length >= 8) || "حداقل 8 کاراکتر",
        max: (v) => (v && v.length <= 100) || "حداکثر 100 کاراکتر",
        email: (v) =>
          (v && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
          "لطفا ایمیل را به درستی وارد کنید",
      },
      comment: {
        description: "",
        name: "",
        email: "",
      },
      items: [
        {
          id: 1,
          title: "تلگرام",
          icon: "/icons/telegram-min.svg",
          link: "https://t.me/share/url?url=https://tetherex.io/blog",
        },
        {
          id: 2,
          title: "توییتر",
          icon: "/icons/twitter.svg",
          link: "https://twitter.com/intent/tweet?url=https://tetherex.io/blog",
        },
        {
          id: 3,
          title: "لینکدین",
          icon: "/icons/linkedin-min.svg",
          link: `https://www.linkedin.com/shareArticle?url=https://tetherex.io/blog`,
        },
      ],
      structuredData: {
        "@context": "http://schema.org",
        "@type": "Article",
        name: "",
        headline: "",
        image: "",
        articleBody: "",
        author: {
          "@type": "Person",
          name: "",
          url: "https://tetherex.io/",
        },
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: "5",
          bestRating: "5",
          ratingCount: "20",
        },
        publisher: {
          "@type": "Organization",
          name: "بک کوین",
          url: "https://tetherex.io",
          logo: "https://tetherex.io/logo.png",
        },
        datePublished: "",
        dateModified: "",
      },
      latest: [],
      mostVisited: [],
      valid: false,
      isOpened: false,
    };
  },

  async fetch() {
    try {
      this.$store.dispatch("overlay/toggleOverlay");
      const response = await this.$axios.$get(
        `/v1/post/${this.$route.params.slug}`
      );
      this.post = response.detail;
      this.latest = response.latest;
      this.mostVisited = response.mostVisited;
      this.$store.dispatch("overlay/toggleOverlay");
    } catch (err) {
      this.$store.dispatch("overlay/toggleOverlay");
      this.$store.dispatch("messagePopup/error", err.response.data.message);
      console.log(err)
    }
  },

  head() {
    this.structuredData.image = this.getImagePath(this.post.image);
    this.structuredData.author.name = this.post.admin.name;
    this.structuredData.name = this.post.title;
    this.structuredData.headline = this.post.title;
    this.structuredData.articleBody = this.post.body;
    this.structuredData.datePublished = this.post.createdAt;
    this.structuredData.dateModified = this.post.updatedAt;

    return {
      title: `${this.post.title}`,
      meta: [
        {
          hid: "description",
          name: "description",
          content: `${this.post.summery}`,
        },
        {
          hid: "keywords",
          name: "keywords",
          content: "تبادل نظر درباره بک کوین, دیدگاه های کاربران بک کوین",
        },
      ],
      script: [{ type: "application/ld+json", json: this.structuredData }],
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          hreflang: "fa",
          href: `https://tetherex.io/blog/${this.$route.params.slug}`,
        },
      ],
    };
  },

  methods: {
    getImagePath(image) {
      if (image) {
        const base64ToArray = image.split(";base64,");
        if (base64ToArray.length > 1) return image;
        else return this.$config.apiUrl + "/" + image;
      }
    },

    getTimeToRead(item) {
      const time = readingTime(item);
      return "حدود " + Math.ceil(time.minutes.toFixed(2)) + " دقیقه";
    },
  },
};
