import { render, staticRenderFns } from "./index.vue?vue&type=template&id=64cb8f35&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=64cb8f35&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64cb8f35",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PublicSliderBlogPosts: require('/app/components/public/slider/BlogPosts.vue').default,CoreBlogDate: require('/app/components/core/BlogDate.vue').default,PublicSliderBlog: require('/app/components/public/slider/Blog.vue').default})
