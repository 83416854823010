
export default {
  name: "AboutUsPage",
  head() {
    return {
      title: "درباره ما",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "تتر اکسچنج | نحوه شکل گیری و راه اندازی وب سایت و آشنایی با مدیران.",
        },
        {
          hid: "keywords",
          name: "keywords",
          content: "درباره تتر اکسچنج, آشنایی با اکسچنج",
        },
        { hid: "logo", name: "image", content: "~/layouts/logos/default.svg" },
        { hid: "robots", name: "robots", content: "index,follow" },
        { hid: "googlebot", name: "googlebot", content: "index,follow" },
      ],
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          hreflang: "fa",
          href: "https://tetherex.io/about-us",
        },
      ],
    };
  },
  data() {
    return {
      features: [
        {
          image: "/layouts/icons/user-circle.svg",
          imgAlt: "user-experience-icon",
          title: "رضایت مشتری اولویت اول ماست",
          desc: "ما بر این باور هستیم که مشتری شایسته بیشترین احترام است  و موفقیت ما در گرو موفقیت مشتریان است",
        },
        {
          image: "/layouts/icons/secure-circle.svg",
          imgAlt: "secure-icon",
          title: "همواره در حال اتقاء امنیت هستیم",
          desc: "پایه و اساس معاملات رمز ارز امنیت است همواره در تلاش برای ارتقاء این اصل مهم هستیم",
        },
        {
          image: "/layouts/icons/service-circle.svg",
          imgAlt: "best-servoce-icon",
          title: "برای ارائه خدمات متنوع تلاش می کنیم",
          desc: "بی وقفه تلاش می کنیم تا  در ارائه خدمات متنوع به مشتریان پیشتاز بوده و بیشترین انتخاب ها را پیش رویشان قرار دهیم",
        },
      ],
    };
  },
};
